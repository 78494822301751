<!--
  This template is responsible for rendering the 'Manage CSV Template' view and handling page changes for the 'Add/Edit CSV Template' page.

  Structure:
  1. The template uses `*ngIf` to conditionally render two views:
     - Manage CSV Template view (default)
     - Add/Edit CSV Template view

  2. Default View:
     - Displays a back button with an SVG icon and the title "Manage CSV Template".
     - Contains a button to navigate to the 'Add/Edit CSV Template' page.
     - Uses a data table component (`app-gis-data-table`) to display the CSV templates with pagination and action buttons.

  3. Add/Edit View:
     - Displays the 'Add/Edit CSV Template' component (`app-add-edit-csv-template`) when `isAddEditCsvTemp` is true.
     - Passes the selected element for editing and handles back navigation via the `goBackEvent`.

  Key Features:
  - Back button allows users to navigate to the previous page.
  - Add CSV upload button triggers the view change to 'Add/Edit CSV Template'.
  - Data table component handles the display of CSV template data, pagination, and actions.
  - Page switching logic is managed by the `changePage` method.
-->
<div *ngIf="!isAddEditCsvTemp && !isQueryIndex">
  <div class="d-flex justify-content-between align-items-center pt-3">
    <!-- Back Button (div) with SVG Icon and Translated Text -->
    <div class="d-flex align-items-center">
      <button type="button" (click)="goBack()" class="border-none bg-none cursor-pointer">
        <svg
        class="mx-1"
        xmlns="http://www.w3.org/2000/svg"
        width="13.991"
        height="24.817"
        viewBox="0 0 12.991 23.817"
      >
        <path
          id="XMLID_225_"
          d="M23.5,75.317a1.083,1.083,0,0,0-1.531,0l-10.06,10.06L1.849,75.317A1.083,1.083,0,0,0,.318,76.848L11.144,87.674a1.082,1.082,0,0,0,1.531,0L23.5,76.848A1.082,1.082,0,0,0,23.5,75.317Z"
          transform="translate(87.991 0) rotate(90)"
          fill="#ff9d5a"
          stroke="#ff9d5a"
          stroke-width="2"
        />
      </svg>
      </button>
      <!-- Title -->
      <h6 class="pl-2 m-0 font-weight-bold">Manage CSV Template</h6>
    </div>

    <!-- Button to switch to Add/Edit CSV Template view -->
    <button class="gis-primary-button" (click)="changePage('add_csv_temp')">
      Add CSV upload
    </button>
  </div>

  <!-- Data Table to display CSV templates with pagination and actions -->
  <app-gis-data-table
    (pageChange)="onPageChange($event)"
    [data]="tableData"
    [columns]="displayColumn"
    [buttons]="buttonConfig"
    [totalCount]="totalCount"
  ></app-gis-data-table>
</div>

<!-- Conditional rendering of Add/Edit CSV Template component -->
<ng-container *ngIf="isAddEditCsvTemp">
  <app-add-edit-csv-template
    [editSelectionElement]="selectedElement"
    (goBackEvent)="changePage('default')"
  ></app-add-edit-csv-template>
</ng-container>

<ng-container *ngIf="isQueryIndex">
  <app-csvtemplate-query [editSelectionElement]="selectedElement" (goBackEvent)="changePage('default')">
  </app-csvtemplate-query>
</ng-container>

<ngx-loading
  [show]="loader"
  [config]="{ backdropBackgroundColour: 'rgba(255,255,255,0.3)' }"
></ngx-loading>