import { Component } from "@angular/core";
import { ApiserviceService } from "../../../../src/app/apiservice.service";
import { SharedModel, initialSharedModel } from "../shared/sharedmodel/shared.model";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import { CopyAttributeProgressComponent } from "./components/copy-attribute-progress/copy-attribute-progress.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-admin-in-progress",
  templateUrl: "./admin-in-progress.component.html",
  styleUrls: ["./admin-in-progress.component.css"],
})
export class AdminInProgressComponent {
  sharedModel: SharedModel = { ...initialSharedModel }; // use common variable from share model file
  totalCount: number; // Total count of records
  isLoading:boolean = false;
  selectedData = [];
  status
  selectedStatus
  columnNameList = [
    "id",
    "module",
    "process_name",
    "user_id",
    // "parameter",
    "attributes",
    // "process_id",
    // "parent_id",
    // "data",
    // "uid",
    // "sub_uid",
    "added_date",
    "updated_date",
    "status",
    "time_taken",
  ];

   // Configuration object for button actions and styles
   buttonConfig = {
    conflict: [
      {
        label: "Process Data",
        action: (element) => this.showData(element), // Function to accept request
        style: {
          border: "1px solid #24CB6B",
          color: "#ffffff",
          padding: "5px 10px",
          width: "100px",
          backgroundColor: "#24CB6B",
          hover: {
            backgroundColor: "#d9ffd9",
          },
        },
      }
    ],
  };
  statusAll: string;
    // Method to get CSS class and color based on status code
    getStatusClass(status: any): { class: string; color: string } {
      // Mapping of status codes to classes and colors
      const StatusClassMap = {
        "-1": { class: "Failed", color: "red" },
        "1": { class: "In progress", color: "blue" },
        "2": { class: "Success", color: "green" },
        "3": { class: "Partially Success", color: "orange" },
        // "0": { class: "Uploaded", color: "purple" },
        // "-3": { class: "Rejected", color: "darkred" },
        // "4": { class: "Accept-progress", color: "yellow" },
        // "3": { class: "Validating", color: "gray" },
      };
      return (
        StatusClassMap[status] || { class: "status-default", color: "black" }
      );
    }
  constructor(
    private api: ApiserviceService,
    private menu:AdminMenusService,
    public dialog: MatDialog,) {
    this.menu.setActive("in-progress");
  }
  ngOnInit() {
    this.inProgres();
  }

  inProgres() {
    let status = this.statusAll;
    let url = `gis-process-status?is_count=true&sort_asc=false&limit=${this.sharedModel.limit}&offset=${this.sharedModel.offset}`;
    // if (this.selectedlayer != val) {
      this.statusAll = "";
      // this.resetStatus();
    // }
    // this.filter = true;
    // this.selectedBuildUid = null;
    if (status) {
      url += "&status=" + status;
    }
    console.log(status, "status");
    this.isLoading = true;
    this.api.getGmsData(url).subscribe({
      next: (res:any) => {
        this.isLoading = false;
        console.log(res, "res");
        this.totalCount = res.totalRecord; // Set total count on first load
        this.selectedData = res.data;
      },
      error: (err) => {
        this.isLoading = false;
        // Handle the error here
      },
    });
  }

   // Method to handle pagination changes
   onPageChange(event: any) {
    this.sharedModel.limit = event.limit; // Update limit based on pagination event
    this.sharedModel.offset = event.offset; // Update offset based on pagination event
    this.inProgres(); // Fetch data for the new page
  }

  showData(element) {
    let dialogRef = this.dialog.open(CopyAttributeProgressComponent, {
      panelClass: "running-process-panel",
      width: "600px",
      disableClose: true,
      data: {
        element:element
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {

      }
    });
  }
}
