import { Component, OnInit, ViewChild } from '@angular/core';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { defaultFormat as _rollupMoment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { NotificationService } from '../services/notification.service';
import { ApiserviceService } from '../../apiservice.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { GisDataService } from '../gis-data-upload/gis-data-upload.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { SocketService } from '../socket.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';



const moment = _rollupMoment || _moment;

export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-gis-sync',
  templateUrl: './gis-sync.component.html',
  styleUrls: ['./gis-sync.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },
  ],
})
export class GisSyncComponent implements OnInit {

  syncType=[
    {name:'Buildings', val:'building', table_name: 'building_log'},
    // {name:'Floors', val:'floors', table_name: 'floor_log'},
    // {name:'Units', val:'units', table_name: 'unit_log'},
    {name:'Governorates', val:'governorates', table_name: 'govern_log'},
    {name:'Neighbourhoods', val:'neighbourhoods', table_name: 'neigh_log'},
    {name:'Master plan', val:'master_plan', table_name: 'master_plan_log'},
    {name:'Blocks', val:'blocks', table_name: 'block_log'},
    {name:'Projects', val:'projects', table_name: 'proj_log'},
    {name:'Streets', val:'streets', table_name: 'street_log'},
    {name:'Parcels', val:'parcels', table_name: 'parcel_log'},
    {name:'Road Data', val:'road', table_name: 'road_log'},
    {name:'Metro station', val:'metro_station', table_name: 'metro_station_log'},
    {name:'Metro', val:'metro', table_name: 'metro_log'},
    {name:'Pcc Parcel', val:'pcc_parcel', table_name: 'pcc_parcels_log'},
    {name:'Project Details', val:'project_details', table_name: 'project_details_log'},
    // {name:'Unit Layout', val:'unit_layout', table_name: 'unit_layout_log'},
    // {name:'Parcel setback', val:'parcel_setback', table_name: 'parcel_setback_log'},
    {name:'Project Road', val:'project_road', table_name: 'project_road_log'},
    {name:'Project Streets', val:'project_streets', table_name: 'project_street_log'},
  ]


  building_uids=[];
  selectedBuildingUid:string='';
  years=[];
  searchType:string='';
  searchyear:string='';
  searchBuilding:string='';

  selectedYear:string=new Date().getFullYear().toString();
  select_type:string='';

  custom_date='';
  // progress_value:any;
  public today = new Date();
  public mindate = new Date('01-01-1999');
  dataSource=[];
  public displayedColumns = [
  'sno',
  'table_name',
  'year',
  'table_progress',
  'from_sync_date',
  'to_sync_date',
  'execution_time',
  'last_sync',
  'status',
  'remarks',
  'action']

  loader: boolean;
  sync_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };

  download_sync = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }

  status_enum={
    '1': 'Progress',
    '2' : 'Completed',
    '-1' : 'Failed',
    '-2': 'Progress stopped'
  }
  searchField: FormControl;
  reportLoader: boolean = false;
  allBuildings: any[]=[];
  buildingLoader: boolean;
  alertmessage: string='Please sync data for download latest data';
  total_record: any=[];

  totalCount=0;
  limit=10;
  offset=0;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  pageEvent: PageEvent;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private fnc: CommonfunctionService,
    private alert : NotificationService,
    private api:ApiserviceService,
    private menus: AdminMenusService,
    private active: GisDataService,
    private socket: SocketService
    ) {
      this.menus.setActive('gis-sync');
      this.active.setActive('');
      this.getAllowResourcesApi();
      setTimeout(() => {
        this.getYears();
      }, 500);
      this.searchField = new FormControl();
  }

  resetBuildFilter(){
    this.searchField = new FormControl();
  }
  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = e.pageSize * e.pageIndex;
    }
    this.getSyncData();
  }

  getDate(date){
    return this.formatDateShow(date);
  }
  ngOnInit(): void {
    this.searchField.valueChanges
    .pipe(debounceTime(800))
      .subscribe(term => {
          term = term.trim();
          if (term && term?.length > 1) {
            let index = this.allBuildings.findIndex(el=> el.building_uid.toLowerCase().includes(term.toLowerCase()));
            if(index > -1){
              this.building_uids = this.allBuildings.filter(ele=> ele.building_uid.toLowerCase().includes(term.toLowerCase()));
            }else{
              this.getBuildings(term);
            }
          } else {
            this.building_uids = this.allBuildings;
          }
        })



///getting data from socket
  this.socket.message$.subscribe((res:any)=>{
    if(res.data && res.data.length>0){
       let socket_list = res.data;
      //  console.log('socket_list >', socket_list);

       socket_list.map(el=>{
        let socket_obj = el;
        const objeIndex =  this.total_record.findIndex((obj => obj.id == socket_obj.id))
        if(objeIndex != -1){
          this.total_record[objeIndex].record_processed =  socket_obj.record_processed;
          this.total_record[objeIndex].record_to_be_processed =  socket_obj.record_to_be_processed;
          setTimeout(() => {
            this.createTable();
          }, 1000);
        }else{
          let entity = this.syncType.find(e=> e.val == this.select_type);
          if(socket_obj.table_name == entity.table_name && socket_obj.year == this.selectedYear){
            this.total_record.splice(0,0,socket_obj);
            this.createTable();
          }
        }
       })
    }
  })

  }

  // get resource list for checking gis-sync resource
  getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe((res: any) => {
        this.api.allowResources = res.data;
        this.sync_access = this.fnc.checkResourceAccess('gis-sync', false);
        this.download_sync = this.fnc.checkResourceAccess('gis-sync-download', false);
        // this.getSyncData();
        this.getBuildings();
      })
  }

  //create year dropdown
  getYears() {
    const currentYear = new Date().getFullYear()
    const startYear  = 2009;
    for (var i = currentYear; i >= startYear; i--) {
      this.years.push({ val: (i).toString() });
    }
  }

  // call sync data api
  startSync(){
    if(!this.sync_access.POST){
      this.alert.notify("You are not authorized to sync data.", 'warn')
      return;
    }
    if(this.select_type == '' || this.select_type == null){
      this.alert.notify('Type is required', 'warn');
      return;
    }else if(this.selectedYear == '' || this.selectedYear == null){
      this.alert.notify('Year is required', 'warn');
      return;
    }
    let body ={
      "entity": this.select_type,
      "source": "GIS",
      "user_id": this.api.user_id,
      "year": this.selectedYear
    }
    if( this.select_type == 'building'){
      body["building_id"]= this.selectedBuildingUid
    }
    if(this.custom_date){
      body["input_date"]= this.formatDateaApi(this.custom_date)
    }else{
      body["input_date"]= null;
    }

    // let selected_type = this.syncType.filter(res=> res.val == this.select_type)[0].name;
    this.loader=true;
    this.api.postGmusData("sync-api", body).subscribe({
      next: (res:any) => {
        this.alert.notify(
          res.message,
          "success"
        );
        this.loader = false;
        this.getSyncData();
      },
      error: (err) => {
        this.loader = false;
        this.alert.notify(err.error.message, "error");
      },
    });
  }

  /**
   * Getting building UIDS
   * @param sreach
   * @returns
   */
  getBuildings(sreach:string=null){
    if(!this.sync_access.GET){
      this.alert.notify("You are not authorized for getting buildings")
      return;
    }
    let url=`guilding-uids`;
    if(sreach) url=`guilding-uids?building_uid=${sreach}`;
    this.buildingLoader=true;
    this.api.getGmusData(url).subscribe({
      next: (res: any) => {
        this.buildingLoader = false;
        if (res) {
          this.building_uids = res;
          if (res?.length) this.allBuildings = [...this.allBuildings, ...res];
        }
      },
      error: (err) => {
        this.buildingLoader = false;
        console.log(err.error.message);
      },
    });
  }

  getTotal(){
    let table_name=''
    if(!this.select_type){
      this.alert.notify("Please select type", 'warn');
      return;
    }else{
      let type = this.syncType.find(e=> e.val == this.select_type);
      table_name = type.table_name;
    }
    let url = `sync-data?year=${this.selectedYear}&table_name=${table_name}`;
    this.api.getGmusData(url).subscribe({
      next: (res: any) => {
        if(res.length){
          this.totalCount = res.length;
        }else{
          this.totalCount = 0;
        }
      },
      error:()=> {this.totalCount = 0;}
    })
  }
  onSelectionChang(){
    this.custom_date = '';
    this.offset=0;
    this.paginator.pageIndex = 0;
    this.getSyncData();
    this.getTotal()
  }

  getSyncData() {
    if (!this.sync_access.GET) {
      this.alert.notify("You are not authorized to get sync data.", 'warn')
      this.reportLoader = false;
      return;
    }
    if (!this.selectedYear) {
      this.alert.notify("Year is required for getting sync data",'warn');
      this.reportLoader = false;
      return;
    }
    let table_name=''
    if(!this.select_type){
      this.alert.notify("Please select type", 'warn');
      return;
    }else{
      let type = this.syncType.find(e=> e.val == this.select_type);
      table_name = type.table_name;
    }
    this.loader = true;
    let url = `sync-data?year=${this.selectedYear}&table_name=${table_name}&limit=${this.limit}&offset=${this.offset}`;
    // if(this.offset==0){
    //   url += `&is_count=true`;
    // }
    this.api.getGmusData(url).subscribe({
      next: (res: any) => {
        // if(this.offset =0){
        //   this.totalCount = res.totalCount;
        // }
        if(res && res.length > 0 ){
          this.loader = false;
          this.total_record=res;
          this.reportLoader = false;
          this.createTable();
        }else{
          this.dataSource = [];
          this.total_record=[];
          this.loader = false;
          this.reportLoader = false;
        }
      },
      error: () => {
        this.dataSource = [];
        this.loader = false;
        this.reportLoader = false;
      },
    });
  }

 // add tooltip
 getTooltip(data){
  return ` Start - ${data?.execution_start_time} \n
           End  - ${data?.execution_end_time}`
 }

  createTable(){
    if(this.total_record.length > 0){
      let tem=[];
      this.total_record.map(ele=>{
        let d={
          'id':ele.id,
          'table_name': ele.table_name,
          'table_progress':  this.fnc.percentageConvertor(ele.record_to_be_processed, ele.record_processed),
          'year': ele.year,
          'execution_time': this.fnc.getDateDifference(ele.execution_start_time, ele.execution_end_time),
          'last_sync':  this.fnc.formatDateUTC(ele.added_date, true),
          'execution_start_time': ele.execution_start_time ? this.fnc.formatDateUTC(ele.execution_start_time, true): '',
          'execution_end_time': ele.execution_end_time ? this.fnc.formatDateUTC(ele.execution_end_time, true): '',
          'from_sync_date': ele.min_time_stamp ? this.fnc.formatDateUTC(ele.min_time_stamp, true) : '',
          'to_sync_date':  ele.max_time_stamp ? this.fnc.formatDateUTC(ele.max_time_stamp, true) :'',
          'remarks': ele.remarks,
          'status': this.status_enum[ele.status],
          'text_status': ele.status,
          'record_to_be_processed':ele.record_to_be_processed,
          'record_processed': ele.record_processed
        }
        tem.push(d)
      })
      this.dataSource = tem;
    }

  }
  // returnVal(element, cols) {
  //   switch (cols) {
  //     case 'last_sync': return element.last_sync ? this.fnc.formatDateUTC(element.last_sync, true) : '';
  //     case 'execution_start_time': return element.execution_start_time ? this.fnc.formatDateUTC(element.execution_start_time, true) : '';
  //     case 'execution_end_time': return element.execution_end_time ? this.fnc.formatDateUTC(element.execution_end_time, true) : '';
  //     case 'execution_time': return element.execution_start_time &&  element.execution_end_time ? this.fnc.getDateDifference(element.execution_start_time, element.execution_end_time ) : '';
  //     case 'table_progress': this.fnc.percentageConvertor(element.record_to_be_processed, element.record_processed);
  //     default: return element[cols];
  //   }
  // }

  formatDateaApi(value: any) {
    if(value){
      value = new Date(value);
      return (
        value.getFullYear() +
        '-' +
        (value.getMonth() + 1).toString().padStart(2, 0) +
        '-' +
        value.getDate().toString().padStart(2, 0)
      );
    }
    return null;
  }
  formatDateShow(value: any) {
    if(value){
      value = new Date(value);
      return (
        value.getDate().toString().padStart(2, 0)         +
        '-' +
        (value.getMonth() + 1).toString().padStart(2, 0) +
        '-' +
        value.getFullYear()
      );
    }
    return null;
  }
  download(element){
    return;
    this.loader=true;
    let tablename;
    if(element.table_name == "building_log"){
      tablename = "buildings"
    } else if(element.table_name == "govern_log"){
      tablename = "governorates"
    } else if(element.table_name == "neigh_log"){
      tablename = "neighbourhoods"
    } else if(element.table_name == "master_plan_log"){
      tablename = "master_plan"
    } else if(element.table_name == "block_log"){
      tablename = "blocks"
    } else if(element.table_name == "proj_log"){
      tablename = "projects";
    }else if(element.table_name == "street_log"){
      tablename = "streets";
    }else if(element.table_name == "parcel_log"){
      tablename = "parcels";
    }else if(element.table_name == "road_log"){
      tablename = "road";
    }
    this.api
      .getGmusData(
        `download-sync?entity=`+tablename+`&year=${element?.year}`
      )
      .subscribe({
        next: (res: any) => {
          this.loader = false;
          if (res && res?.data) {
            this.alert.notify(res?.message, "success");
            window.open("https://" + res?.data, "_blank");
          } else {
            this.alert.notify(res?.message, "warn");
          }
        },
        error: (err) => {
          this.loader = false;
          this.alert.notify(err.error.message, "error");
        },
      });
  }
  refresh_Data(){
    this.reportLoader = true;
    this.getSyncData();
  }


stopProcess(element){
  if(!this.sync_access.PATCH){
    this.alert.notify('You are not authorize to stop process', 'warn');
    return
  }
  let tablename;
    if(element.table_name == "building_log"){
      tablename = "buildings"
    } else if(element.table_name == "govern_log"){
      tablename = "governorates"
    } else if(element.table_name == "neigh_log"){
      tablename = "neighbourhoods"
    } else if(element.table_name == "master_plan_log"){
      tablename = "master_plan"
    } else if(element.table_name == "block_log"){
      tablename = "blocks"
    } else if(element.table_name == "proj_log"){
      tablename = "projects";
    }else if(element.table_name == "street_log"){
      tablename = "streets";
    }else if(element.table_name == "parcel_log"){
      tablename = "parcels";
    }else if(element.table_name == "road_log"){
      tablename = "road";
    }
    else if(element.table_name == "pcc_parcels_log"){
      tablename = "pcc_parcel";
    }else if(element.table_name == "project_details_log"){
      tablename = "project_details";
    }
    else if(element.table_name == "unit_layout_log"){
      tablename = "unit_layout";
    }else if(element.table_name == "parcel_setback_log"){
      tablename = "parcel_setback";
    }else if(element.table_name == "project_road_log"){
      tablename = "project_road";
    }
    else if(element.table_name == "project_street_log"){
      tablename = "project_streets";
    }
  let url = `stop-sync-process?sync_id=${element.id}&process_name=${tablename}&user_id${this.api.user_id}`;
  this.loader = true;
  this.api.getGmusData(url)
    .subscribe(
      {
        next: (res: any) => {
          this.alert.notify(res.message, "success");
          this.loader = false;
          this.getSyncData();
        },
        error:(err) => {
          this.alert.notify(err.error.message, "error");
          this.loader = false;
          this.getSyncData()
        }
      }
    );
}

}
