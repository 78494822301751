import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ApiserviceService } from "../../../../../../src/app/apiservice.service";
import { NotificationService } from "../../../../../../src/app/admin/services/notification.service";
import { CommonfunctionService } from "../../../../../../src/app/services/commonfunction.service";
import { ConfirmDialogBox } from "../../../../../../src/app/admin/confirm-dialog/confirm-dialogbox";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { SocketService } from "../../../../../../src/app/admin/socket.service";

@Component({
  selector: "app-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.scss"],
})
export class DataTableComponent {
  @Output() AfterProcessStopped = new EventEmitter<any>();
  @Output() afterSynced = new EventEmitter<any>();
  @Input() totalCount:any;
  @Input() selectedLayerType:any;
  @Output() pageChange = new EventEmitter<any>();
  displayedColumns: string[] = [
    "S. No",
    "Upload id",
    "Name",
    "added_date",
    "Updated_date",
    "count",
    "new_count",
    "Log_files",
    "Status",
    "Action",
  ];
  dataSource: any;
  columnVisibility: { [key: string]: boolean } = {};
  showSettings: boolean = false;
  @Input() layerdata: any;
  @Input() pageIndex: number;
  @Input() offset: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // totalCount: any;
  pages: any = [];
  total: number;
  sizeOptions: any = [5, 10, 15, 25];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  defaultPageSize: number = 10;
  pageEvent: PageEvent;
  // pageIndex = 0;
  limit: number = 10;
  calculate_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  loader = false;
  // offset: number = 0;
  total_record: any;
  constructor(
    private api: ApiserviceService,
    private notify: NotificationService,
    private fnc: CommonfunctionService,
    private dialog: MatDialog,
    private socket: SocketService,
    private cdr : ChangeDetectorRef
  ) {
    // Set default visibility for columns
    this.displayedColumns.forEach((column) => {
      this.columnVisibility[column] = true;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["selectedLayerType"]) {
      if (this.selectedLayerType === "direction") {
        // Check if "sync_record_processed" is not already in the array
        if (!this.displayedColumns.includes("sync_record_processed")) {
          this.displayedColumns.splice(4, 0, "sync_record_processed");
        }
      } else {
        // Remove the column when switching to a different layer
        this.displayedColumns = this.displayedColumns.filter(col => col !== "sync_record_processed");
      }
    }
  }


  ngOnInit() {
    this.getAllowResourcesApi();

    // Initialize default values for each record in layerdata
    this.layerdata = this.layerdata.map((ele) => ({
      ...ele,
      record_processed: ele.record_processed || 0,
      record_to_be_processed: ele.record_to_be_processed || 0,
      table_progress: this.percentageConvertor(
        ele.record_to_be_processed || ele.total_sync_records,
        ele.record_processed || ele.record_processed
      ),
    }));

    this.dataSource = new MatTableDataSource(this.layerdata);

    // Handle subscription to list changes
    this.list.subscribe((res) => {
      if (!res) {
        this.dataSource = new MatTableDataSource([]);
        return;
      }

      res = res.map((ele) => ({
        ...ele,
        record_processed: ele.record_processed || 0,
        record_to_be_processed: ele.record_to_be_processed || 0,
         table_progress: this.percentageConvertor(
          ele.record_to_be_processed || ele.total_sync_records,
          ele.record_processed || ele.record_processed
        ),
      }));

      this.dataSource = new MatTableDataSource(res);
    });
  }


  // getting allow resources
  getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe((res: any) => {
      this.api.allowResources = res.data;
      this.getAllowResource();
    });
  }

  getAllowResource() {
    this.calculate_access = this.fnc.checkResourceAccess(
      "spark_calculate_sync",
      false
    );
    this.gettingDataFromSocket();
  }
  @Input() list: Observable<any>;

  ngAfterContentChecked(): void {}
  toggleSettings() {
    this.showSettings = !this.showSettings;
  }

  /**
   * log file is downloading here
   * @param log_url
   */
  downloadLog(log_url) {
    window.open(log_url.toString(), "_blank");
  }

  /**
   * stop running process
   * @param element
   */
  stopProcess(element) {
    if (!this.calculate_access.DELETE) {
      this.notify.notify("You are not authorized to stop Process", "warn");
      return;
    }
    let batch_id = element.batch_id;
    let upload_id = element.id;
    let url = `delete-calculate-process-spark?batch_id=${batch_id}&upload_id=${upload_id}`;
    this.loader = true;
    this.api.deleteGesData(url).subscribe({
      next: (res: any) => {
        this.loader = false;
        if (res && res.status == 200) {
          this.notify.notify(res.message, "success");
          this.AfterProcessStopped.emit();
        } else {
          // this.layerListData = [];
          this.loader = false;
        }
      },
      error: (err) => {
        this.notify.notify(err.error.message, "error");
        this.loader = false;
      },
    });
  }
  /**
   * date formate change here
   * @param date
   * @returns
   */
  getFormatedDate(date) {
    return this.fnc.formatDateUTC(date, true);
  }

  /**
   * open confirmation model before stop process
   * @param element
   */
  openConfirmation(element) {
    let _data = {
      parent_data: this,
      message: "Do you really want to Stop Running Process?",
    };
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: "350px",
      data: _data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "YES") {
        this.stopProcess(element);
      }
    });
  }

  confirmDialogYes(confirm, action) {
    if (confirm == "YES") {
      //
    }
  }

  syncProcess(element) {
    let url = `sync-data-spark?upload_id=${element.id}&year=${element.year}`;
    this.loader = true;
    this.api.getGesData(url).subscribe({
      next: (res: any) => {
      if(res.status == 200){
        this.afterSynced.emit()
        this.loader = false;
        this.notify.notify(res.message, "success");
      }else{
        this.loader = false;
        this.notify.notify(res.message, "warn");
      }
      },
      error: (err) => {
        this.loader = false;
      },
    });
  }

  pageAction(e) {
    let pagedetails = {
      limit: e.pageSize,
      offset: e.pageIndex * e.pageSize,
      pageIndex: e.pageIndex
    }
   this.pageChange.emit(pagedetails)
  }


   gettingDataFromSocket() {
    this.socket.gis_spark_message$.subscribe((res: any) => {
      if (res.data && res.data.length > 0) {
        const socket_list = res.data;

        socket_list.forEach((socket_obj) => {
          const objIndex = this.layerdata.findIndex((obj) => obj.id === socket_obj.id);

          // Calculate progress safely
          const total = socket_obj.record_to_be_processed || 0;
          const processed = socket_obj.record_processed || 0;
          const newProgress = socket_obj.per;//total > 0 ? this.percentageConvertor(total, processed) : 0;

          if (objIndex !== -1) {
            // Update existing record
            this.layerdata[objIndex] = {
              ...this.layerdata[objIndex],
              record_processed: processed,
              record_to_be_processed: total,
              table_progress: newProgress,
            };
          } else {
            // Add new record
            this.layerdata.push({
              ...socket_obj,
              record_processed: processed,
              record_to_be_processed: total,
              table_progress: newProgress,
            });
          }
        });

        // Force table update
        this.dataSource.data = JSON.parse(JSON.stringify(this.layerdata));
      }
    });
  }



  percentageConvertor(total: number, processed: number): number {
    if (total <= 0) {
      return 0; // Avoid division by zero or invalid totals
    }
    return Math.min(100, Math.round((processed / total) * 100)); // Calculate percentage
  }


}
